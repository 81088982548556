@use "@angular/material" as mat;

$primary-font-family: 'Graphik', 'Helvetica Neue', sans-serif;
$secondary-font-family: 'Verdana', 'Arial', 'Helvetica', sans-serif;
$icon-font-family: 'icon';
$primary-ppt-h1-title: 38px;
$primary-ppt-h2-title: 30px;
$primary-ppt-h3-title: 20px;
$primary-ppt-h4-title: 18px;
$primary-common-h1-title: 33px;
$primary-common-h2-title: 24px;
$primary-common-h3-title: 19px;
$primary-common-h4-title: 18px;
$primary-font-size-title: 20px;
$primary-font-size-subtitle: 19px;
$primary-font-size-smalltitle: 18px;
$primary-font-size-xlarge: 17px;
$primary-font-size-large: 16px;
$primary-font-size: 15px;
$primary-font-size-small: 14px;
$secondary-font-size: 13px;
$tertiary-font-size: 12px;
$copyright-font-size: 10px;
$table-title-font-size: 23px;
$primary-icon-size-large: 25px;
$primary-icon-size-normal: 20px;
$primary-icon-size-small: 18px;
$primary-icon-size-smaller: 10px;
$primary-text-black-dark: rgba(0, 0, 0, .87);
$primary-text-black-light: rgba(0, 0, 0, .6);
$primary-text-black-lighter: rgba(0, 0, 0, .38);
$primary-text-gray-dark:#757575;
$primary-text-white: rgba(255, 255, 255, .87);
$primary-bg-color-white: rgba(255, 255, 255, .87);
$primary-text-gray: #717073;
$primary-font-weight-normal: 400;
$primary-font-weight-medium: 500;
$primary-font-weight-bold: 600;
$primary-font-weight-bolder: 700;
$primary-white-space: normal;
$secondary-white-space: pre-wrap;
$tertiary-white-space: nowrap;
$primary-word-break: break-word;
$primary-line-height: 1.67;
$secondary-line-height: 1.5;
$tertiary-line-height: 1.2;
$list-line-height: 1.58;
$list-view-line-height: 1.42;
$normal-line-height: normal;
$primary-disabled-opacity: .5;
$primary-icon-opacity: .54;
$primary-opacity: .15;
$secondary-opacity: .12;
$no-opacity: 1;

$no-margin: 0;
$no-padding: 0;
$no-border-radius: 0;

$primary-palette: (
  50: #CBC3E2,
  100: #EED6FF,
  200: #CB8EC5,
  300: #AB67D5,
  400: #B13FFF,
  500: #A100FF,
  600: #8D0BF9,
  700: #6E11F2,
  800: #7800C6,
  900: #0016E9,
  A100: #E6DCFE,
  A200: #9D0DC1,
  A300: #460073,
  A400: #4B0377,
  contrast: (
    50: rgba(0, 0, 0, .87),
    100: rgba(0, 0, 0, .87),
    200: rgba(0, 0, 0, .87),
    300: rgba(0, 0, 0, .87),
    400: rgba(0, 0, 0, .87),
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: rgba(0, 0, 0, .87),
    A200: rgba(0, 0, 0, .87),
    A300: #FFFFFF,
    A400: #FFFFFF,
  )
);

$accent-palette: (
  50: #F1F3F5,
  100: #C7CBFF,
  200: #9FAAFF,
  300: #7287FF,
  400: #4A6AFF,
  500: #004DFF,
  600: #0043F3,
  700: #0037E6,
  800: #002ADB,
  900: #0007CC,
  contrast: (
    50: rgba(0, 0, 0, .87),
    100: rgba(0, 0, 0, .87),
    200: rgba(0, 0, 0, .87),
    300: rgba(0, 0, 0, .87),
    400: rgba(0, 0, 0, .87),
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

$warning-palette: (
  50: #FFE8EF,
  100: #FFC5D3,
  200: #F98A99,
  300: #F25770,
  400: #FF004E,
  500: #FF0030,
  600: #F70030,
  700: #E5002A,
  800: #D80022,
  900: #C90015,
  A100: #B00020,
  contrast: (
    50: rgba(0, 0, 0, .87),
    100: rgba(0, 0, 0, .87),
    200: rgba(0, 0, 0, .87),
    300: rgba(0, 0, 0, .87),
    400: rgba(0, 0, 0, .87),
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
  )
);

$primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
$accent-color: mat.m2-get-color-from-palette($accent-palette, 500);
$warn-color: mat.m2-get-color-from-palette($warning-palette, 400);
$success-color: #3E8629;
$warning-color: #AD5F00;
$error-color: #B10B02;
$header-border-color: #E4E8EE;
$disabled-color: #F3F3F3;
$disabled-text-color: #7B7B7B;
$placeholder-color: #9EA0A5;
$placeholder-color-dark: #757575;
$table-bg-color: #F0F0F0;
$content-bg-color: #F9F9F9;
$horizontal-rule-color: #DCDCDC;
$scrollbar-thumb-color: #DCDCDC;
$scrollbar-thumb-color-hover: #C1C1C1;
$clear-btn-color-hover: #F5F5F5;
$catalogue-status-bg-color: #4F4F4F;
$filter-section-bg-color: rgba(161, 0, 255, .03);
$image-bg-color: rgba(0, 0, 0, .07);
$sidebar-focus-bg-color: rgba(0, 0, 0, .04);
$box-shadow-color-lighter: rgba(0, 0, 0, .04);
$box-shadow-color-light: rgba(204, 204, 204, 1);
$box-shadow-color-normal: rgba(0, 0, 0, .15);
$box-shadow-color-dark: rgba(0, 0, 0, .38);
$box-shadow-color-darker: rgba(0, 0, 0, .5);
$box-shadow-color-disabled: rgba(102, 113, 123, .21);
$project-overlay-bg-color: rgba(0, 0, 0, .47);
$metrics-table-bg-color: rgba(218, 225, 243, 1);
$metrics-table-border-color: rgba(173, 187, 214, 1);
$metrics-table-title-color: rgb(18, 179, 244, 1);
$border-color-lighter: rgba(0, 0, 0, .06);
$border-color-light: rgba(0, 0, 0, .1);
$border-color-normal: rgba(0, 0, 0, .15);
$border-color-dark: rgba(0, 0, 0, .38);
$border-color-darker: rgba(0, 0, 0, .6);
$dropdown-option-bg-light: rgba(0, 0 , 0, .12);
$dropdown-option-bg-dark: rgba(0, 0, 0, .04);
$tooltip-bg-color: rgba(97, 97, 97, .9);


