@font-face {
  font-family: "icon";
  src: url("/assets/fonts/icon/icon.eot?c3zv2t");
  src: url("/assets/fonts/icon/icon.eot?c3zv2t#iefix") format("embedded-opentype"), url("/assets/fonts/icon/icon.ttf?c3zv2t") format("truetype"), url("/assets/fonts/icon/icon.woff?c3zv2t") format("woff"), url("/assets/fonts/icon/icon.svg?c3zv2t#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"],
[class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-sort_down:before {
  content: "\e90f";
  color: #a100ff;
  right: 2.5px;
  position: absolute;
  top: 6px;
  font-size: 12px;
}
.ic-sort_up:before {
  content: "\e910";
  color: #a100ff;
  right: 2.5px;
  position: absolute;
  top: 6px;
  font-size: 12px;
}
.ic-close-button:before {
  content: "\e90e";
}
.ic-check:before {
  content: "\e900";
}
.ic-close:before {
  content: "\e901";
}
.ic-cloud_up:before {
  content: "\e902";
}
.ic-dropdown:before {
  content: "\e903";
  color: #9ea0a5;
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: 6px;
}
.ic-edit:before {
  content: "\e904";
}
.ic-error:before {
  content: "\e905";
}
.ic-favorite:before {
  content: "\e906";
}
.ic-file:before {
  content: "\e907";
}
.ic-filter:before {
  content: "\e908";
}
.ic-live_help:before {
  content: "\e909";
}
.ic-menu:before {
  content: "\e90a";
}
.ic-plus:before {
  content: "\e90b";
}
.ic-recommend:before {
  content: "\e90c";
}
.ic-search:before {
  content: "\e90d";
}
