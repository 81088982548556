@use '@angular/material' as mat;
@include mat.core();

@import 'variables';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~font-awesome/css/font-awesome.css';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($ai-typography-config);`
// @include mat.all-legacy-component-typographies($ai-typography-config);
// @include mat.legacy-core();

$ai-typography-config: mat.m2-define-legacy-typography-config(
  $font-family: $primary-font-family
);
$ai-primary: mat.m2-define-palette($primary-palette, 500);
$ai-accent: mat.m2-define-palette($accent-palette, 500);
$ai-warning: mat.m2-define-palette($warning-palette, 400);
$ai-common-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ai-primary,
      accent: $ai-accent,
      warn: $ai-warning
    ),
    density: 0
  )
);

@include mat.all-component-themes($ai-common-theme);
@include mat.all-component-typographies($ai-typography-config);

html,
body {
  height: 100%;
}

body {
  margin: $no-margin;
  font-family: mat.m2-font-family($ai-typography-config);
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Scrollbar Corner */
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border-radius: 12px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-thumb-color-hover;
}

* {
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

app-root {
  display: flex;
  height: 100vh;
  min-height: 100%;
}

app-login,
app-home,
app-error {
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  min-height: 100%;
}

.mat-divider {
  border-top-color: $border-color-lighter;
}

[hidden],
.hidden-menu {
  display: none !important;
}

.mdc-button {
  white-space: $tertiary-white-space;
}

.white-space-pre {
  white-space: $secondary-white-space;
  word-break: $primary-word-break;
  & ~ p {
    white-space: $secondary-white-space;
    word-break: $primary-word-break;
  }
}

.mat-drawer-container {
  background-color: #ffffff;
  color: $primary-text-black-dark;
}

.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      box-shadow: 0 2px 4px 0 $box-shadow-color-darker;
      border-radius: $no-border-radius !important;
    }
  }
}

.live-menu {
  &.sort-menu {
    min-width: 190px !important;
    .mat-mdc-menu-item {
      padding-right: 20px;
    }
  }
  box-shadow: 0 1px 4px 0 $box-shadow-color-darker !important;
  color: $primary-text-black-dark;
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      padding: 0px 10px;
      min-height: 24px;
      .mdc-list-item__primary-text {
        line-height: $primary-line-height;
        height: 24px;
        .icon {
          font-size: $tertiary-font-size;
          position: absolute;
          right: 10px;
          top: 6px;
          span {
            position: absolute;
            top: 0px;
            left: 2.4px;
          }
        }
      }
      &[disabled] {
        opacity: $primary-disabled-opacity;
      }
    }
  }
  .mat-divider {
    margin: 10px 0px;
    border-top-color: $border-color-light;
  }
}

.filter-wrap {
  border-radius: 4px;
  background-color: $filter-section-bg-color;
  padding: 14px 10px;
  margin-bottom: 15px;
  .btn-position {
    justify-content: flex-end;
  }
  label {
    font-size: $tertiary-font-size;
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .grid {
    margin: 0px -5px;
    .form-group {
      padding: 0px 5px;
    }
  }
  .mdc-button {
    padding: 0px 22px;
    font-size: $primary-font-size;
    font-weight: $primary-font-weight-normal;
    text-transform: uppercase;
    &[disabled] {
      color: $primary-color;
      opacity: $primary-disabled-opacity;
    }
    &.mat-mdc-unelevated-button {
      color: $primary-text-white;
      &[disabled] {
        background-color: $primary-color;
      }
    }
    &.mat-mdc-outlined-button {
      border-color: $primary-color;
    }
  }
  &.catalogue-filter {
    margin-top: -5px;
  }
}

// dropdown list
.mat-mdc-option {
  .mdc-list-item__primary-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: $tertiary-white-space !important;
    color: $primary-text-black-dark;
  }
  &.mdc-list-item {
    mat-pseudo-checkbox {
      display: none;
    }
  }

  &:hover:not(.mdc-list-item--disabled),
  &:focus:not(.mdc-list-item--disabled) {
    background: $dropdown-option-bg-dark;
  }
  &.mat-mdc-option-active {
    background: #ffffff;
    color: $primary-text-black-dark;
  }
  &.mdc-list-item--selected {
    &.mat-mdc-option-multiple {
      &:not(.mdc-list-item--disabled) {
        background: #ffffff;
      }
    }
    &:not(.mat-mdc-option-multiple) {
      &:not(.mdc-list-item--disabled) {
        background: #ffffff;
      }
    }
  }
}

// Apply tags in filter section
.filter-values {
  align-items: center;
  margin: 10px 0px;
  label {
    font-size: $tertiary-font-size;
  }
  .tag {
    height: 30px;
    border-radius: 5px;
    border: solid 1px $accent-color;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    font-size: $tertiary-font-size;
    color: $accent-color;
    span {
      text-transform: capitalize;
    }
    .remove_tag {
      font-size: $primary-icon-size-smaller;
      margin-left: 9px;
      cursor: pointer;
    }
  }
  .clear-btn {
    user-select: none;
    overflow: hidden;
    height: 30px;
    border-radius: 5px;
    border: solid 0.5px $border-color-dark;
    font-size: $tertiary-font-size;
    font-weight: $primary-font-weight-normal;
    color: $primary-text-black-lighter !important;
    text-transform: capitalize;
    &.mat-mdc-button {
      --mat-mdc-button-persistent-ripple-color: transparent;
      --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.05);
    }
    &:hover {
      background-color: $clear-btn-color-hover;
    }
  }
  &.catalogue-filter {
    margin-bottom: 15px;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: flex-start;
  position: relative;
  label {
    font-size: $tertiary-font-size;
    line-height: $primary-line-height;
    color: $primary-text-black-light;
    margin-bottom: 7px;
    text-transform: uppercase;
    span {
      font-size: $tertiary-font-size;
      line-height: $primary-line-height;
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      margin-left: 2px;
      font-weight: $primary-font-weight-normal;
    }
  }
  .hint-message {
    position: absolute;
    right: 3px;
    top: -7px;
    font-size: $primary-font-size-small;
    color: $primary-text-black-light;
  }
  .hint {
    margin-top: 10px;
    font-size: $primary-font-size;
    color: $primary-text-black-light;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    max-height: 17px;
    &.error {
      display: none;
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      &.show {
        display: block;
      }
    }
  }
  textarea {
    min-height: 250px;
    padding: 6px 16px !important;
    font-family: inherit;
    resize: vertical;
    &.short {
      min-height: 90px;
    }
  }
  .mat-mdc-autocomplete-trigger,
  select {
    position: relative;
    background-image: url(/assets/icons/dropdown.svg);
    background-position: calc(100% - 17px) 14px;
    background-repeat: no-repeat;
  }
  .mat-mdc-progress-bar {
    top: -3px;
    margin: 0px 4px;
    width: calc(100% - 8px);
    height: 3px;
  }
  .form-control {
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border: solid 1px $border-color-normal;
    padding: 0px 16px;
    font-size: $primary-font-size;
    font-family: mat.m2-font-family($ai-typography-config);
    line-height: $primary-line-height;
    color: $primary-text-black-dark;
    outline: none;
    &:focus {
      border-color: $primary-color;
    }
    &:disabled {
      box-shadow: inset 0 1px 2px 0 $box-shadow-color-disabled;
      border: solid 1px $border-color-normal;
      background-color: $disabled-color;
    }
    &.error {
      border-color: mat.m2-get-color-from-palette($warning-palette, A100);
    }
  }
  input[type='file'] {
    display: none;
  }
  .mat-mdc-select {
    background-image: url(/assets/icons/dropdown.svg);
    background-position: calc(100% - 17px) 14px;
    background-repeat: no-repeat;
    background-color: #ffffff;
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border: solid 1px $border-color-normal;
    &:focus {
      border-color: $primary-color;
    }
    &.mat-mdc-select-disabled {
      border: solid 1px $border-color-normal;
      background-color: $disabled-color;
    }
    .mat-mdc-select-trigger {
      height: 37px;
      width: 100%;
      padding-right: 30px;
      display: inline-table;
      .mat-mdc-select-value {
        height: 37px;
        padding: $no-padding;
        line-height: 37px;
        display: table-cell;
        max-width: 0;
        .mat-mdc-select-value-text,
        .mat-mdc-select-placeholder {
          padding: 0px 16px;
          color: $primary-text-black-dark;
        }
      }
      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
  }
  .mat-mdc-radio-group {
    margin-left: -10px;
    .mat-mdc-radio-button {
      label {
        font-size: $primary-font-size;
        color: $primary-text-black-dark;
        text-transform: none;
        margin-bottom: 0;
      }
    }
  }
  .image-preview {
    max-width: 431px;
    width: 100%;
    height: 265px;
    box-shadow: 0 1px 2px 0 $box-shadow-color-normal;
    background-color: $image-bg-color;
    position: relative;
    .image-preview-inner {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      z-index: 1;
    }
    .label-btn {
      position: absolute;
      right: 15px;
      bottom: 15px;
      height: 38px;
      padding: 0px 10px;
      border-radius: 4px;
      border: solid 0.8px $accent-color;
      background-color: $primary-bg-color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $primary-font-size;
      font-weight: $primary-font-weight-normal;
      line-height: $primary-line-height;
      color: $accent-color;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 2;
      .icon {
        font-size: $primary-icon-size-normal;
        color: $accent-color;
        margin-right: 10px;
      }
      i {
        font-size: $primary-font-size;
        color: $primary-color;
        margin-right: 10px;
      }
      &::before {
        display: none;
      }
    }
  }
  &:not(.customized-chips) {
    // normal chip tags
    .mat-mdc-chip-set {
      &.mdc-evolution-chip-set {
        padding: $no-padding;
        .mdc-evolution-chip-set__chips {
          .mat-mdc-chip {
            &.mdc-evolution-chip {
              margin-top: 10px;
              margin-right: 2px;
              margin-bottom: 0px;
              min-height: 30px;
              height: auto;
              border-radius: 5px;
              padding: 4px 8px;
              border: solid 0.5px $accent-color;
              background-color: #ffffff !important;
              font-size: $tertiary-font-size;
              transform: translateZ(0);
              &:last-child {
                margin-right: 0px;
              }
              .mdc-evolution-chip__cell--primary {
                margin-left: 0px;
                .mdc-evolution-chip__action--primary {
                  padding-left: 4px;
                  padding-right: 4px;
                  .mdc-evolution-chip__text-label {
                    color: $accent-color;
                    word-break: $primary-word-break;
                    white-space: $primary-white-space;
                  }
                }
              }
              .mat-mdc-chip-focus-overlay {
                background: #ffffff;
              }
              .mat-mdc-chip-remove {
                font-size: $primary-icon-size-smaller;
                color: $accent-color !important;
                width: 10px;
                height: 10px;
                opacity: $no-opacity;
                padding-left: 4px;
                padding-right: 4px;
                &:hover,
                &:focus {
                  opacity: $primary-icon-opacity;
                }
              }
              .mat-chip-ripple {
                display: none;
              }
              &:active {
                box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%),
                  0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
              }
            }
          }
        }
      }
    }
  }
  &.error {
    & > label {
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      padding-left: 30px;
      position: relative;
      &:before {
        content: '\e905';
        font-size: $primary-icon-size-normal;
        font-family: $icon-font-family;
        color: mat.m2-get-color-from-palette($warning-palette, A100);
        position: absolute;
        left: 0px;
        top: -6.5px;
      }
    }
    .form-control,
    .mat-mdc-select {
      border-color: mat.m2-get-color-from-palette($warning-palette, A100);
    }
  }
}

// snack bar
.mat-mdc-snack-bar-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $box-shadow-color-normal;
  width: 330px;
  min-height: 85px;
  margin-top: 95px !important;
  margin-right: 15px !important;
  .mdc-snackbar__surface {
    min-height: 85px;
    background: transparent !important;
    padding-left: 35px !important;
    min-width: 330px !important;
  }
  .mdc-button {
    min-width: 25px;
    padding: $no-padding;
    position: absolute;
    right: 0px;
    top: -5px;
    font-weight: $primary-font-weight-normal;
    color: transparent !important;
  }
  .mdc-button__label {
    content: '';
    position: absolute;
    width: 15px;
    height: 17px;
    right: 8px;
    top: 5px;
    background-image: url(/assets/icons/close.svg);
  }
  &.success,
  &.warning,
  &.error {
    &::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 35%;
      width: 25px;
      height: 25px;
    }
  }
  &.success {
    background: $success-color;
    &::before {
      background-image: url(/assets/icons/success_msg.svg);
    }
  }
  &.warning {
    background: $warning-color;
    &::before {
      background-image: url(/assets/icons/warning_msg.svg);
    }
  }
  &.error {
    background: $error-color;
    &::before {
      background-image: url(/assets/icons/error_msg.svg);
    }
  }
}

// top left button of detail view
.top-btn-wrap {
  & > div {
    position: relative;
    border: 2px solid mat.m2-get-color-from-palette($primary-palette, 800);
    border-radius: 6px;
    font-size: $primary-font-size-small;
    cursor: pointer;
    padding: 5px 10px;
    &.back {
      padding-left: 20px;
      color: mat.m2-get-color-from-palette($primary-palette, 800);
      font-weight: $primary-font-weight-bold;
      &::before {
        content: '';
        position: absolute;
        left: 6px;
        top: 8px;
        width: 10px;
        height: 10px;
        background-image: url(/assets/icons/angle-left.svg);
      }
    }
    &.share {
      padding-left: 25px;
      background-color: mat.m2-get-color-from-palette($primary-palette, 800);
      color: #ffffff;
      &::before {
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url(/assets/icons/share.svg);
      }
    }
    &.edit-btn {
      width: 200px;
      height: 40px;
      line-height: 36px;
      border: 2px solid mat.m2-get-color-from-palette($primary-palette, A200);
      border-radius: 5px;
      color: mat.m2-get-color-from-palette($primary-palette, A200);
      text-align: center;
      padding: $no-padding;
      .icon {
        line-height: 30px;
        vertical-align: middle;
        font-size: $primary-font-size;
        .fa {
          vertical-align: middle;
          margin-right: 8px;
        }
      }
    }
  }
}

.right-column,
.owner-wrap {
  .title-name {
    font-weight: $primary-font-weight-medium;
  }
  .view-bg {
    background: mat.m2-get-color-from-palette($primary-palette, A100);
    padding: 4px 8px;
    color: mat.m2-get-color-from-palette($primary-palette, A200);
    border-radius: 4px;
    font-size: $primary-font-size-small;
    &.data-set {
      margin-bottom: 5px;
    }
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    background: $tooltip-bg-color;
    font-size: $primary-font-size-small;
    line-height: 20px;
    margin: 10px;
    max-width: 250px;
    will-change: auto;
  }
}

hr {
  margin-left: 50px;
  width: 90%;
  margin-bottom: 25px;
  border: 1px solid $horizontal-rule-color;
}

.button-display {
  float: right;
  padding-right: 65px;
  padding-bottom: 25px;
  .btn {
    font-size: $primary-font-size;
    color: $accent-color;
  }
  .add-icon {
    padding-right: 8px;
    font-size: $primary-font-size-xlarge;
    padding-bottom: 4px;
  }
}

.WavSelect {
  .ng-placeholder {
    color: $placeholder-color-dark !important;
  }
}
.WavOption {
  .ng-option-disabled {
    color: $placeholder-color-dark !important;
  }
}

.mat-mdc-paginator {
  background-color: transparent !important;
  .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label {
    color: $primary-text-black-light;
  }
}

.acn-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #333333;
  opacity: 0.5;
  z-index: 9999;
  color: #212529;
}
.acn-consent-dialog {
  background-color: #ffffff;
  color: #212529;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 800px;
  max-height: 560px;
  z-index: 9999;
  padding: 0 0 45px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Graphik', sans-serif;
}
.acn-consent-pg {
  max-width: 600px !important;
}
.acn-consent-bdy-ai {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  box-sizing: border-box;
  padding: 20px;
}
.acn-consent-body {
  margin-bottom: 20px;
}
.acn-u-btn,
a.acn-u-btn:link,
a.acn-u-btn:visited {
  text-decoration: none !important;
  padding: 8px 15px;
  border: 1px solid #333333;
  color: #000000;
  background-color: #ffffff;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-size: 0.9em;
  cursor: pointer;
}
input.acn-u-btn.acn-consent-btn-blue.acn-consent-priv-continue {
  background: $primary-color !important;
  border: $primary-color !important;
  padding: 8px 15px !important;
  line-height: 1.3em !important;
  margin: 0 !important;
  min-width: 90px;
}
.acn-consent-btn {
  padding-top: 10px;
  text-align: right !important;
  padding-right: 40px;
}

// dialog
.confirm-dialog {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 30px;
  .dialog-title {
    color: $primary-text-black-dark;
    text-transform: uppercase;
    font-size: $primary-font-size-title;
    font-weight: $primary-font-weight-medium;
    margin-bottom: 16px;
    margin-top: 0;
  }
  .dialog-content {
    p {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      color: $primary-text-black-dark;
      margin-top: 0;
    }
    & > p {
      margin-bottom: 25px;
      font-weight: $primary-font-weight-normal;
      &:last-child {
        margin-bottom: 30px;
      }
    }
    ul {
      margin-top: -10px;
      margin-bottom: 30px;
      list-style: none;
      padding: $no-padding;
      &:not(:last-child) {
        margin-top: -15px;
      }
      li {
        font-size: $primary-font-size;
        line-height: $primary-line-height;
        color: $primary-text-black-dark;
      }
    }
  }
  .dialog-actions {
    justify-content: flex-end;
    .mdc-button {
      padding: 0px 22px;
      font-size: $primary-font-size;
      font-weight: $primary-font-weight-normal;
      text-transform: uppercase;
      &[disabled] {
        color: $primary-color;
        opacity: $primary-disabled-opacity;
        &.btn-delete {
          color: $warn-color;
        }
      }
      &.mat-mdc-unelevated-button {
        color: $primary-text-white;
        &[disabled] {
          background-color: $primary-color;
          &.btn-delete {
            background-color: $warn-color;
          }
        }
      }
      &.mat-mdc-outlined-button {
        border-color: $primary-color;
        &.btn-delete {
          border-color: $warn-color;
        }
      }
    }
  }
}

// login & error page
.single-page {
  background-color: mat.m2-get-color-from-palette($accent-palette, 50);
  justify-content: center;
  .single-page-box-wrap {
    width: 100%;
    max-width: 450px;
    border-radius: 10px;
    box-shadow: 0 25px 50px 0 $box-shadow-color-lighter;
    background-color: #ffffff;
    padding: 50px;
    margin: $no-margin auto;
    .single-page-header {
      align-items: center;
      margin-bottom: 40px;
      h1 {
        font-size: $primary-common-h1-title;
        font-weight: $primary-font-weight-bolder;
        line-height: 1.06;
        color: $primary-text-black-dark;
        margin: $no-margin;
        &.center {
          text-align: center;
        }
      }
    }
    .single-page-content {
      p {
        font-size: $primary-font-size-large;
        line-height: $normal-line-height;
        text-align: center;
        color: $primary-text-gray;
        margin: 0px 0px 20px;
      }
    }
    .single-page-bottom {
      button {
        height: 45px;
        border-radius: 6px;
        font-size: $primary-font-size-large;
        font-weight: $primary-font-weight-normal;
        margin: 28px 0 0;
      }
    }
  }
}
